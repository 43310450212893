const theme = {
  colors: {},
};

export const textColor = "#382F2D";

export const accentColor = "#683090";

export const primaryColor = "#EC008C";

export default theme;
