import styled, { ThemeProvider } from "styled-components";
import GothamRounded from "./fonts/fonts";
import GlobalStyle from "./styles/globalStyle";
import theme from "./styles/theme";
import { accentColor, textColor } from "./styles/theme";

const Main = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 500;
  // padding: 2rem 0 1.6rem 0;
  margin: 0;
  color: ${accentColor};
  position: sticky;
  top: 0;
  height: 5rem;
  z-index: 1;
  background-color: #fff;
`;

const ContentContainer = styled.div`
  max-width: 120rem;
  width: 100%;
`;

const SectionContainer = styled.div`
  position: relative;
  flex-direction: column;
  padding: 0 0.4rem 1rem 0.4rem;
  width: 100%;
`;

const SectionHeader = styled.h3`
  padding: 1.2rem 0rem 0rem 0rem;
  margin: 0;
  font-size: 1.2rem;
  color: ${textColor};
`;

const ContentBox = styled.div`
  display: flex;
  width: 100%;
  border-radius: 2rem;
  background-color: #fff;
  margin-bottom: 1rem;

  flex-direction: column;

  @media (min-width: 1280px) {
    margin-bottom: 4rem;
  }
`;

const ContentWrapper = styled.div`
  padding: 0rem 6rem 5rem 6rem;
  @media (max-width: 800px) {
    padding: 0rem 0rem 1rem 0rem;
  }
`;

const ParagraphContent = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 120%;
  font-weight: 300;
  color: ${textColor};
`;

const ParagraphDivContent = styled.div`
  font-size: 1.2rem;
  line-height: 120%;
  font-weight: 300;
  color: ${textColor};
`;

const ListItemContent = styled.li`
  font-size: 1.2rem;
  line-height: 120%;
  color: ${textColor};
  font-weight: 300;
  padding-top: 0.2rem;
`;

const Highlighter = styled.span`
  font-weight: 500;
`;

const UnorderedList = styled.ul`
  padding-left: 2.4rem;
  @media (min-width: 768px) {
    padding-left: 4rem;
  }
`;

const UnorderedListNoStyle = styled.ul`
  list-style-type: none;
  padding-left: 0rem;
  @media (min-width: 768px) {
    padding-left: 0rem;
  }
`;

const OrderedListLetter = styled.ol`
  list-style-type: none;
  counter-reset: ${(props) => (props.startWith ? `section ${props.startWith}` : `section 8`)};
  padding-left: 0;
  & > li:before {
    content: "(" counter(section, lower-alpha) ")";
    padding-right: 0.4rem;
  }
  & > li {
    counter-increment: section;
    padding-left: 0;
    padding-top: 0.2rem;
  }
`;

const OrderedListLetterRight = styled.ol`
  list-style-type: none;
  counter-reset: ${(props) => (props.startWith ? `section ${props.startWith}` : `section 8`)};
  padding-left: 0;
  & > li:before {
    content: counter(section, lower-alpha) ")";
    padding-right: 0.4rem;
  }
  & > li {
    counter-increment: section;
    padding-left: 0;
    padding-top: 0.2rem;
  }
`;

const UnorderedListDash = styled.ul`
  list-style: none;
  padding-left: 2.4rem;
  @media (min-width: 768px) {
    padding-left: 4rem;
  }
  & > li:before {
    display: inline-block;
    content: "-";
    margin-left: -2rem;
    margin-right: 1rem;
  }
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GothamRounded />
      <GlobalStyle />
      <Main>
        <Title>Terms and Conditions</Title>
        <ContentContainer>
          <SectionContainer>
            <ContentBox>
              <ContentWrapper>
                <SectionHeader>2GO TRAVEL</SectionHeader>
                <ParagraphContent>
                  Please go through the terms and conditions carefully. Your acceptance of these is required in 2GO
                  Travel's Online Ticketing facility.
                </ParagraphContent>
                <SectionHeader>BOARDING REQUIREMENTS</SectionHeader>
                <ParagraphDivContent>
                  <UnorderedList>
                    <ListItemContent>One (1) Printed Copy of the eTicket Itinerary Receipt.</ListItemContent>
                    <ListItemContent>Presentation of the Passengers Valid ID.</ListItemContent>
                    <ListItemContent>Travel documents required by national or local authorities.</ListItemContent>
                  </UnorderedList>
                  <SectionHeader>
                    2GO Travel reserves the right to refuse boarding if passengers cannot present these documents upon
                    request.
                  </SectionHeader>
                </ParagraphDivContent>

                <SectionHeader>eTICKET ITINERARY RECEIPT</SectionHeader>
                <ParagraphContent>
                  The eTicket Itinerary Receipt is non-transferable. It is valid within six (6) months from the date of
                  the original trip. Unused eTickets can be Refunded or Revalidated within its validity period only. See
                  Return Policy below for details.
                </ParagraphContent>

                <SectionHeader>GOVERNMENT-MANDATED DISCOUNTS </SectionHeader>
                <SectionHeader>SENIOR CITIZENS</SectionHeader>
                <ParagraphContent>
                  Senior Citizen discount will apply/compute for passengers whose age is 60 years old or above and once
                  the passengers ID number is entered. The 20% Senior Citizen discount applies to Base Rate only. The
                  eTicket Facility displays the lowest available promo rate at the time of booking. Therefore, if the
                  promo rate is available, it will automatically apply/compute instead of the Senior Citizen discount.
                  12% VAT will also be deducted from the fare. You may call the Customer Service hotline or visit our
                  Booking Outlets should you need to compare rates.
                </ParagraphContent>
                <ParagraphContent>
                  A valid Senior Citizen ID (issued by Office of the Senior Citizens Affairs, Philippines.) or any valid
                  Philippine Government Issued ID's bearing the birthdate of the passenger must be presented during
                  inspection and boarding. Failure to present the required ID will forfeit the discount and ticket will
                  have to undergo Revalidation (See Return Policy).
                </ParagraphContent>
                <ParagraphContent>
                  The Senior Citizens discount is applicable to Filipino Nationalities only.
                </ParagraphContent>
                <ParagraphContent>
                  Senior Citizens are suggested to travel with a legal-aged companion and to bring a medical certificate
                  indicating they are fit to travel. They maybe subjected for check-up by the Vessel Doctor or Nurse on
                  the day of departure. It is within discretion of the Vessel Doctor or Nurse if the passenger is fit to
                  travel and will be allowed to board.
                </ParagraphContent>
                <SectionHeader>INFANT</SectionHeader>
                <ParagraphContent>
                  Infants who are 1 month to 2.11 years old, and below one meter in height are considered INFANTS and
                  maybe allowed to board. They are advised to secure a medical certificate and identification proving
                  their relationship with the accompanying passengers.
                </ParagraphContent>
                <ParagraphContent>
                  A fixed rate of Php 250.00 will be charged per infant regardless of destination or accommodation. All
                  surcharges are waived.
                </ParagraphContent>
                <ParagraphContent>
                  A separate ticket will be issued for the infant, however, he/she will be sharing his/her parent or
                  guardian's bunk or room.
                </ParagraphContent>
                <ParagraphContent>
                  No more than two (2) infants will be allowed per adult passenger. The succeeding infants will be
                  charged full promo fare or 75% off on base rates plus all the surcharges.
                </ParagraphContent>
                <ParagraphContent>
                  Infants will not be boarded unless accompanied by an adult (parent or guardian).
                </ParagraphContent>

                <SectionHeader>PREGNANT PASSENGERS</SectionHeader>
                <ParagraphContent>
                  Passengers who are more than 24 weeks pregnant will be denied boarding the vessel.
                </ParagraphContent>
                <ParagraphContent>
                  Pregnant passengers must be present a medical certificate showing that gestation or pregnancy is 24
                  weeks or below. They are also suggested to travel with legal-aged companion. They maybe subjected for
                  check-up by the Vessel Doctor or Nurse on the day of departure. It is within the discretion of the
                  Vessel Doctor or Nurse if the passenger is fit to travel and will be allowed to board
                </ParagraphContent>
                <SectionHeader>MINOR PASSENGERS</SectionHeader>
                <ParagraphContent>
                  Minor passengers who are below 18 years old and are travelling alone, MUST BE accompanied by their
                  parents or legal guardians at the port. They should be endorsed to the Boarding Officers where the
                  parent or guardian will be required to sign a waiver.
                </ParagraphContent>
                <ParagraphContent>
                  Minor passengers should be fetched by their declared representatives at the port of destination. The
                  passenger will be put on-hold for release and will be endorsed to Department of Social Welfare and
                  Development (DSWD) in the absence of their representatives.
                </ParagraphContent>

                <SectionHeader>
                  OTHER DISCOUNTS: PERSONS WITH DISABILITIES (PWDs), STUDENTS AND MEDAL OF VALOR AWARDEES
                </SectionHeader>
                <ParagraphContent>
                  For Persons With Disability, Medal of Valor awardees and Students, kindly proceed to our Corporate
                  Booking Outlets and Authorized Retail Outlets to avail of the Special Discounts.
                </ParagraphContent>
                <ParagraphContent>
                  Please note that the Special Discounts are applicable to the Base Rate only and will not apply to
                  discounted or promo rates.
                </ParagraphContent>

                <SectionHeader>RETURN POLICY</SectionHeader>
                <ParagraphContent>
                  Customers may send an email Refund request to eticket@2go.com.ph using the registered email address of
                  the transaction. Only original etickets that are not boarded in status and has not undergone rebooking
                  will be processed.
                </ParagraphContent>
                <ParagraphContent>
                  For Credit Card/Debit Card, reversal is 7-15 days to cardholder's account. Refund request must be
                  submitted within 120 days from purchase date for it to be facilitated via email otherwise, refund will
                  be directed over our Corporate Ticketing Outlets.
                </ParagraphContent>
                <ParagraphContent>
                  For Credit Card/Debit Card, reversal is 7-15 days to cardholder's account. Refund request must be
                  submitted within 120 days from purchase date for it to be facilitated via email otherwise, refund will
                  be directed over our Corporate Ticketing Outlets. A credit card voucher will be issued and processing
                  is within 30 banking days.
                </ParagraphContent>
                <ParagraphContent>
                  For GCash, if refund request is placed within 120 days from purchase date, posting to account is real
                  time. Beyond this, refund should be facilitated over our Corporate Ticketing Outlets. Refund is in
                  Cash Form.
                </ParagraphContent>
                <ParagraphContent>
                  For Other Cash and ATM paid bookings, refund should be done over our Corporate Ticketing Outlets.
                </ParagraphContent>
                <ParagraphContent>
                  For direct refund requests over our Corporate Ticketing Outlets, the account holder or passenger are
                  the valid requestors and is required to submit the complete set of itinerary and present a valid
                  government issued ID. If the passenger or account holder will authorize a representative to request
                  for the procedure, the representative must present a signed authorization letter and his/her valid ID
                  as well.
                </ParagraphContent>

                <ParagraphContent>Refund is subject to the following surcharges:</ParagraphContent>
                <ParagraphContent>
                  <UnorderedList>
                    <ListItemContent>
                      Before Vessel Departure - Php 300.00 surcharge + Web Admin Fee per Ticket
                    </ListItemContent>
                    <ListItemContent>
                      fter Departure - Php 300.00 surcharge + Php 300.00 No Show Fee + Web Admin Fee per Ticket
                    </ListItemContent>
                  </UnorderedList>
                </ParagraphContent>
                <ParagraphContent>
                  * No Partial refunds shall be given for the tickets availed on room rates. Refunds are released upon
                  surrender of ALL issued tickets for a particular room.
                </ParagraphContent>

                <ParagraphContent>
                  Unused/Unscanned eTickets can also be REVALIDATED. Revalidation is any change in details of the ticket
                  except for the passenger name or age, origin and destination of trip. This can be processed at the
                  Corporate Ticketing Outlets. Present the eTicket Itinerary and valid ID.
                </ParagraphContent>

                <ParagraphContent>Revalidation is subject to the following surcharges:</ParagraphContent>
                <ParagraphContent>
                  <UnorderedList>
                    <ListItemContent>
                      Before Vessel Departure - Php 300.00 surcharge + Php 36.00 Revalidation Fee + Fare Difference per
                      ticket
                    </ListItemContent>
                    <ListItemContent>
                      After departure - Php 300.00 surcharge + Php 300.00 No Show Fee + Php 36.00 Revalidation Fee +
                      Fare Difference per Ticket
                    </ListItemContent>
                  </UnorderedList>
                </ParagraphContent>

                <SectionHeader>Additional information</SectionHeader>
                <ParagraphContent>
                  <UnorderedList>
                    <ListItemContent>
                      Refund and Revalidation surcharges are waived if the trips are affected by typhoon or force
                      majeure and operational issues such as technical, problem, extended/emergency dry-dock, preventive
                      maintenance or any change in the trip as initiated by 2GO Travel.
                    </ListItemContent>
                    <ListItemContent>NO SHOW FEE is PHP 300.00 and charged per ticket.</ListItemContent>
                    <ListItemContent>Web Admin Fee is NON-REFUNDABLE.</ListItemContent>
                    <ListItemContent>
                      If the passenger or cardholder will authorize a representative to request for over-the-counter
                      Refund or Revalidation, the representative must submit/present the original signed authorization
                      letter of the cardholder or passenger, actual valid ID (Government issued) of the cardholder or
                      passenger and valid ID of the representative.
                    </ListItemContent>
                  </UnorderedList>
                </ParagraphContent>
                <SectionHeader>&nbsp;</SectionHeader>
                <SectionHeader>2GO EXPRESS</SectionHeader>
                <ParagraphDivContent>
                  <UnorderedList>
                    <ListItemContent>
                      <Highlighter>GENERAL PROVISIONS.</Highlighter> Carrier and/or Forwarder (2GO Express, Inc.),
                      shipper, owner and consignee of the goods are bound by the terms and conditions of this Contract.
                      This contract is NON-NEGOTIABLE and the shipper acknowledges that it has been prepared by the
                      owner/shipper or by a Carrier and/or Forwarder’s representative on behalf of the owner/shipper.
                      The owner/shipper warrants that he/she/it is the owner of the goods transported hereunder or is
                      the authorized agent the owner of the goods, and that he/she/it hereby accepts Carrier and/or
                      Forwarder's terms and conditions for himself/herself/itself and agent for and on behalf of any
                      person having any interests in the Shipment. No employee, agent, servant or representative of
                      Carrier and/or Forwarder has authority to alter, modify or waive any provision of this contract.
                    </ListItemContent>
                    <ListItemContent>
                      <Highlighter>PAYMENT OF FREIGHT CHARGES.</Highlighter> Owner/Shipper and/or consignee shall pay
                      the corresponding freight charges of the rate within stated and all other charges accruing on said
                      items according to actual weight or volume before delivery. If an inspection it is found that the
                      articles are not the same as those described on the face of this Contract, freight charges shall
                      be paid upon the articles actually shipped.
                    </ListItemContent>
                    <ListItemContent>
                      <Highlighter>OWNER'S/SHIPPER'S WARRANTIES AND INDEMNITY.</Highlighter> The owner/shipper shall
                      indemnify and hold 2GO harmless for any loss or damage arising out of the owner’s/shipper’s
                      failure to comply with any applicable laws or regulations and for the owner’s/shipper's breach of
                      the following warranties and representations:
                      <ParagraphDivContent>
                        <OrderedListLetterRight startWith="0">
                          <ListItemContent>
                            All information provided by the owner/shipper is complete and accurate. The sum entered on
                            the face of this Contract as owner's/shipper's declared value for carriage shall constitute
                            as the true value of the goods
                          </ListItemContent>
                          <ListItemContent>
                            The owner/shipper protected the shipment against unauthorized Interference during
                            preparation, storage, and transportation to the Carrier and/or Forwarder{" "}
                          </ListItemContent>
                          <ListItemContent>
                            The goods are properly marked, addressed, and packed to ensure safe transportation with
                            ordinary care in handling
                          </ListItemContent>
                          <ListItemContent>
                            All applicable laws and regulations have been complied therewith{" "}
                          </ListItemContent>
                          <ListItemContent>
                            This Contract has been signed by the owner/shipper and the Terms and Conditions constitute
                            binding and enforceable obligations between the parties as stated in ltem 1.
                          </ListItemContent>
                          Any and all damages arising from any discrepancy due to false or incorrect declaration made by
                          the owner/shipper or the person who caused the particulars to be placed herein whether
                          specifically instructed by the owner/shipper or not, shall be for the account of the
                          owner/shipper.
                        </OrderedListLetterRight>
                      </ParagraphDivContent>
                    </ListItemContent>
                    <ListItemContent>
                      <Highlighter>
                        DANGEROUS AND ILLEGAL GOODS AND CARRIER AND/OR FORWARDER'S RIGHT TO INSPECT.
                      </Highlighter>{" "}
                      If the owner/shipper delivers inflammables, explosives or other articles of a dangerous character,
                      the shipper shall be liable for all loss or damage caused thereby, and such goods shall be
                      warehoused at his expense, or if safe warehousing impossible, same may be destroyed without
                      compensation to the owner, shipper, or consignee or the cargo. Carrier and/or Forwarder will not
                      receive for shipment any article the transportation of which is prohibited by law, and if any such
                      goods be shipped in any manner, the owner/shipper will not only be liable to such penalties as may
                      be imposed by law but also to damages sustained by the Carrier and/or Forwarder in consequence of
                      such shipment and, in addition, Carrier and/or Forwarder may, upon discover thereof, turnover such
                      illegal goods to government authorities, or throw such goods overboard or otherwise destroy them.
                      The owner/shipper agrees to the opening of the container and packaging of his goods for inspection
                      of inflammables, explosives, other articles of a dangerous character, illegal substance or
                      contraband goods that may pose risk and danger to Carrier and/or Forwarder’s vessel or to other
                      goods. The Carrier and/or Forwarder shall have the right to reject to transport the cargo in case
                      the shipper refuses to have its cargo inspected.
                    </ListItemContent>
                    <ListItemContent>
                      <Highlighter>WITHDRAWAL OF SHIPMENT.</Highlighter> In case of “to-Pier” services, it is the duty
                      of the Consignee to withdraw the shipment from the Carrier and/or Forwarder within 48 hours after
                      vessel's actual time of arrival. In case of “to-Door” services, especially freight collect mode of
                      payment, the Consignee shall tender payment upon delivery of the package of cargo. Otherwise, the
                      said package or cargo shall be kept in the Carrier and/or Forwarder's storage area and it is the
                      duty of the consignee to retrieve the said package or cargo from the Carrier and/or Forwarder.
                      Failure to do so shall be a ground for the Carrier and/or Forwarder to charge the consignee the
                      regular rate for storage fees to commence after 48 hours lead time has lapsed in case (a) above
                      and the following day in case (b) above after actual delivery. In case of airport-airport service
                      it is the duty of the Consignee to withdraw the shipment from the Carrier and/or Forwarder 24
                      hours after service of notice. In case of door-to-door services, especially freight collect made
                      of payment, the consignee shall tender payment upon delivery of the cargo. Otherwise the said
                      cargo shall be kept in the Carrier and/or Forwarder’s storage area and it is the duty of the
                      consignee to retrieve the said cargo from the Carrier and/or Forwarder. Failure to do so shall be
                      a ground for the Carrier and/or Forwarder to charge to consignee the regular rates for storage
                      fees to commence one day after 24 hours lead time has lapsed in case (a) above and the following
                      day in case (b) above after actual delivery. The Carrier and/or Forwarder reserves the right to
                      dispose the said shipment when the declared value on the face thereof is equivalent to that of the
                      accumulated storage fees. It shall be the duty of the Carrier and/or Forwarder to inform consignee
                      within three (3) calendar days prior to actual disposal of the subject shipment.
                    </ListItemContent>
                    <ListItemContent>
                      <Highlighter>PERIOD OF CARRIER AND OR FORWARDER'S RESPONSIBILITY OVER THE GOODS.</Highlighter>{" "}
                      Carrier and/or Forwarder’s responsibility over the goods ceases upon their actual delivery to the
                      consignee or after consignee has been advised of the arrival of the goods and has reasonable
                      opportunity thereafter to remove them or otherwise dispose of them
                    </ListItemContent>
                    <ListItemContent>
                      <Highlighter>RETURN OF UNDELIVERED ITEMS.</Highlighter> If the Carrier and/or Forwarder cannot
                      complete the delivery of the goods to the consignee by reason of incomplete, wrong, or inexistent
                      address, or of consignee's or his authorized representative's absence or refusal to receive the
                      items, the Carrier and/or Forwarder shall notify the owner/shipper of such failure and,
                      thereafter, shall hold the items and attempt to complete the delivery of the goods one last time.
                      If delivery cannot be completed despite the second attempt, the goods shall be returned to the
                      owner/shipper with the latter required to pay for all the charges, including but not limited to
                      forwarding, disposal or return transportation charges, as well as duty and tax, if applicable. If
                      the owner/shipper refuses return of items or the items cannot be returned, Carrier and/or
                      Forwarder reserves the right to deal with the goods thereafter in any manner in its discretion.
                      The Carrier and/or Forwarder shall not be liable for any claims for loss or damage to items
                      refused by the owner/shipper on return.
                    </ListItemContent>
                    <ListItemContent>
                      <Highlighter>CARRIER AND/OR FORWARDER'S LIABILITY.</Highlighter> Carrier and/or Forwarder shall
                      transport and deliver the goods safely to destination, but shall not be liable for damages, loss,
                      destruction or deterioration of the goods due to any of the following causes: (a) flood, storm,
                      earthquake lightning or other natural disaster or calamity; (b) act of public enemy in war,
                      whether international or civil; (c) act or omission of the shipper or owner of the goods; (d) the
                      character of the goods or defects in the packing or in the containers; (e) order or acts of
                      competent authority; (f) acts of piracy or robbery.
                      <ParagraphDivContent>
                        <UnorderedListNoStyle>
                          <ListItemContent>
                            Perishable goods and fragile Items shall be transported wholly at risk of owner/shipper.
                            Loss, destruction, or deterioration of perishable and fragile goods shall be deemed due to
                            the nature and character of said goods; hence, the Carrier and/or Forwarder shall not be
                            liable, unless the loss, destruction, or deterioration is proven to be directly caused by
                            acts or omissions of the Carrier and/or Forwarder.
                          </ListItemContent>
                          <ListItemContent>
                            The Carrier and/or Forwarder does not warrant arrival of the goods at any given date of
                            time, unless otherwise represented. The Carrier and/or Forwarder shall not be liable for
                            loss, destruction, or deterioration of the goods due to delay resulting from; (a) force
                            majeure; (b) typhoons or rough seas; (c) orders of the courts or government agencies; (d)
                            stoppage or deviation of the transporting vessel to lend assistance in maritime accidents.
                          </ListItemContent>
                          <ListItemContent>
                            If through the order of public authority the goods are seized or destroyed, the Carrier
                            and/or Forwarder is not liable.
                          </ListItemContent>
                          <ListItemContent>
                            Carrier and/or Forwarder shall not be liable for loss or damage from jettison, worms,
                            vermin, rats, quarantine and sanitary measures, stoppages of transit, legal process of
                            attachment, seizure or forfeiture, or accidents. Wrong description of cargo shall release
                            the Carrier and/or Forwarder from all responsibility in case of seizure and detention
                            thereof.
                          </ListItemContent>
                          <ListItemContent>
                            Carrier and/or Forwarder shall not be liable for the detention or wrongful delivery of any
                            goods nor for consequences due to the insufficiency of the address or obliteration, errors,
                            or inaccuracies of marks and numbers.
                          </ListItemContent>
                          <ListItemContent>
                            Carrier and/or Forwarder shall not be liable in any event for consequential or special
                            damages or other indirection, however arising, whether or not Carrier and/or Forwarder had
                            knowledge that such damages might be incurred, including, but not limited to loss of income,
                            profits, interest, utility or loss of market.
                          </ListItemContent>
                        </UnorderedListNoStyle>
                      </ParagraphDivContent>
                    </ListItemContent>
                    <ListItemContent>
                      <Highlighter>LIMITATION OF LIABILITY.</Highlighter> Without prejudice to the provision on
                      consequential damages, the liability of the Carrier and/or Forwarder for any loss or damages to
                      the shipment, which terms shall include all documents or parcels to be delivered by the Carrier
                      and/or Forwarder, under this contract, is limited to the lesser of,
                    </ListItemContent>
                    <ListItemContent>The amount of declared value.</ListItemContent>
                    <ListItemContent>
                      The amount of loss or damage to a document or parcel actually sustained or
                    </ListItemContent>
                    <ListItemContent>
                      The actual value of the document or parcel whereof, without regard to each commercial utility or
                      special value to the shipper. The actual value of a document (which terms shall include any item
                      of no commercial value) which is transported hereunder shall be ascertained by reference to its
                      cost preparation, replacement, reconstruction or reconstitution at the time and place of shipment,
                      whichever is less. On the other hand, the actual value of a parcel (which terms shall include
                      anything of commercial value) which is transported hereunder shall be ascertained by reference to
                      its cost repair or replacement, resale at fair market value at the time and place of shipment,
                      whichever is less. In no event shall such value exceed the original cost of the article paid by
                      the shipper plus 10%. For package or cargo transported by water, Carrier and/or Forwarder shall
                      not be liable for loss or damage from any cause or for any reason to an amount exceeding{" "}
                      <Highlighter>THREE HUNDRED PESOS (Php 300.00) Philippine Currency</Highlighter> for any single
                      package, unless the value and contents of such package or cargo are correctly declared on the face
                      hereof at the time of shipment, and freight is fully paid in accordance with the actual
                      measurement or chargeable weight of the package or cargo shipped or in accordance with the
                      stipulations between the parties in another separate agreement relevant to the shipment. For
                      package or cargo transported by air, the Carrier and/or Forwarder shall not be liable for loss or
                      damage for any cause or for any reason to an amount exceeding{" "}
                      <Highlighter>TWO HUNDRED PESOS (PHP 200.00) Philippine Currency</Highlighter> for any single
                      package, unless the value and contents of such cargo or package are correctly declared on the face
                      hereof at the time of shipment, and freight is fully paid in accordance with actual measurement,
                      or chargeable weight of the cargo shipped, or in accordance with the stipulation between the
                      parties and another separate agreement relevant to the shipment.{" "}
                      <Highlighter>
                        Carrier and/or Forwarder and its employees and agents shall not be made liable at all for
                        certain losses and damages, and if ever they are to be made liable, the amount of liability is
                        strictly limited to the amount stated above. Owner/Shipper is, therefore, advised to purchase
                        cover to ensure that his/her/its interests are fully protected in all events.
                      </Highlighter>
                    </ListItemContent>
                    <ListItemContent>
                      <Highlighter>CLAIMS.</Highlighter> For package or cargo transported by land, any claim shall be
                      presented by the owner/shipper in writing and shall be filed to the office of Carrier and/or
                      Forwarder nearest the location where the goods were accepted, within thirty (30) days from the day
                      of acceptance. No claim maybe made against Carrier and/or Forwarder outside of the said period. No
                      claim for loss or damage will be entertained until all transportation charges, have been paid. The
                      amount of any such claim may not be deducted from any transportation charges owned by the Carrier
                      and/or Forwarder.
                      <ParagraphDivContent>
                        <br />
                        <OrderedListLetter startWith="0">
                          For package or cargo transported by water, claims against Carrier and/or Forwarder shall be
                          governed by the following procedure:
                          <ListItemContent>
                            if the package or cargo shows exterior signs of damage at the time of delivery to the
                            consignee, any claim for partial loss, shortage or damage may be entertained and considered
                            valid provided the consignee notes the said loss, shortage or damage on the Proof of
                            Delivery or Carrier and/or Forwarder's Stub Copy at the time of delivery to the consignee or
                            his agent. In the absence of such notation, no claims may be entertained. If the freight
                            charge of the package or cargo showing signs of exterior damage at the time of delivery has
                            been paid by the Shipper or is on the shipper's account, the shipper must be file a written
                            claims within 5 days from the time the consignee receive the package or cargo.{" "}
                          </ListItemContent>
                          <ListItemContent>
                            If the package or cargo does not show exterior signs of damage, a written claim within 24
                            hours from the time of delivery of the package or cargo must be made by the consignee if the
                            freight charged of the said package or cargo is to be collected from or charged to
                            consignee; If the freight charged was paid by or is on shipper's account a written claim
                            must be filed by the shipper within 5 days from the time that the consignee received the
                            package or cargo.
                          </ListItemContent>
                          <ListItemContent>
                            A written claim for non-delivery of a package or cargo must be presented to the Carrier
                            and/or Forwarder within thirty days from the date of transaction. Failure to file claims, or
                            to institute judicial proceeding shall constitute a waiver of the claim or right of action.
                          </ListItemContent>
                        </OrderedListLetter>
                      </ParagraphDivContent>
                      <br />
                      <ParagraphDivContent>
                        <OrderedListLetter startWith="0">
                          For package or cargo transported by air, claims against Carrier and/or Forwarder shall
                          governed by the following procedure:
                          <ListItemContent>
                            If the package or cargo shows exterior signs of damage at the time of delivery to the
                            Consignee, any claim for partial loss, shortage or damage may be entertained and considered
                            valid provided the consignee notes the said loss shortage or damage on the Proof of Delivery
                            of Carrier and/or Forwarder's Stub copy at the time of delivery to the consignee or his
                            agent. In the absence of such notation, no calms may be entertained. If the freight charge
                            of the package or cargo showing signs of exterior damage at the time of delivery has been
                            paid by the shipper or is on the shipper's account, the shipper must be a written claim
                            within 5 days from the time that the consignee received the package or cargo; If the freight
                            charge is to be collected from or charge to consignee, the consignee must file a claim
                            within 5 days from his her receipt of the package or cargo.{" "}
                          </ListItemContent>
                          <ListItemContent>
                            if the package or cargo does not show exterior signs of damage, a written claim within 24
                            hours from time of the delivery of the package or cargo must be made by the consignee. If
                            the freight charge of the said package or cargo is to be collected from or charged to
                            consignee; If the freight charged was paid by or is on shipper's account a written claim
                            must be filed by the shipper within 5 days from the time that the consignee received that
                            package or cargo.
                          </ListItemContent>
                          <ListItemContent>
                            A written claim for non-delivery of package or cargo must be presented to the Carrier and/or
                            Forwarder within 30 days from the date of transaction. Failure to file claims, or to
                            institute judicial proceeding shall constitute a waiver of the claim or rights of action.
                          </ListItemContent>
                        </OrderedListLetter>
                      </ParagraphDivContent>
                    </ListItemContent>
                    <ListItemContent>
                      <Highlighter>LIEN ON GOODS SHIPPED.</Highlighter> Carrier and/or Forwarder shall have a lien on
                      all goods shipped for freight charges and/or advances of other charges of any kind arising out of
                      transportation hereunder, and may refuse to surrender possession of the goods until such charges
                      are paid.
                    </ListItemContent>
                    <ListItemContent>
                      <Highlighter>PROTECTION SEGREGATION AND SECURITY OF DATA UNDERTAKING.</Highlighter> CARRIER AND/OR
                      FORWARDER, as data user shall agree and warrant that it shall comply with all the requirements
                      imposed in accordance with the Data Privacy Act of 2012, for the holding, storing of processing of
                      any and all information which is ‘personal information’ or ‘personal data’. There shall likewise
                      be segregation of data by the CARRIER AND/OR FORWARDER as to data of the OWNER/SHIPPER as well as
                      those pertaining to data of other owners/shippers of the CARRIER AND/OR FORWARDER. CARRIER AND/OR
                      FORWARDER shall:{" "}
                    </ListItemContent>
                    <ListItemContent>
                      Use the Personal Data only as reasonably required in connection with the performance of the
                      Services and in accordance with the directions provided by owner/shipper, and shall not keep the
                      Personal Data longer than necessary for processing;
                      <ParagraphDivContent>
                        <OrderedListLetter startWith="1">
                          <ListItemContent>
                            Implement and maintain all reasonable technical and organizational security measures to
                            prevent unauthorized or accidental access, processing, interference, modification, erasure,
                            loss, exposure or use of any Personal Data, and to ensure that only CARRIER AND/OR FORWARDER
                            personnel/s who reasonably require access to the Personal Data are provided with such
                            access;
                          </ListItemContent>
                          <ListItemContent>
                            Provide owner/shipper with written notice upon knowledge of any breach of its data
                            protection obligations under this Agreement, any unauthorized use or disclosure of, or
                            complaint made by a third part in relation to, any Personal Data handled in connection with
                            this Agreement or of any enforcement proceeding against it under the Data Privacy Act;
                          </ListItemContent>
                          <ListItemContent>
                            Comply with its Privacy Policy, procedures and processes with respect to Personal Data from
                            time to time;
                          </ListItemContent>
                          <ListItemContent>
                            Provide Owner/Shipper with written notice as soon as reasonably possible when it becomes
                            aware that the use or disclosure of the Personal Data is required or authorized by under
                            law;
                          </ListItemContent>
                          <ListItemContent>
                            Not disclose personal data to any third parties other than its employees to whom the
                            disclosure Is imperative for the provision of the Services provided it is made subject to
                            obligations of confidentiality no less onerous than those imposed upon CARRIER AND/OR
                            FORWARDER, or to any regulator provided CARRIER AND/OR FORWARDER will notify Owner/Shipper
                            of any such disclosure immediately after it becomes aware of that requirement unless
                            Owner/Shipper requires in writing otherwise;
                          </ListItemContent>
                          <ListItemContent>
                            In case it shall engage another Carrier and/or Forwarder, the CARRIER AND/OR FORWARDER shall
                            ensure that the same obligations for data protection under the contract or legal act are
                            implemented, taking into account the nature of the processing;
                          </ListItemContent>
                          <ListItemContent>
                            Make available to Owner/Shipper all information necessary to demonstrate compliance with the
                            obligations laid down in the Act, and allow for and contribute to audits, including
                            inspections, conducted by the personal information controller or another auditor mandated by
                            the latter.
                          </ListItemContent>
                        </OrderedListLetter>
                      </ParagraphDivContent>
                    </ListItemContent>
                  </UnorderedList>
                </ParagraphDivContent>
                <ParagraphDivContent>
                  <UnorderedList>
                    CARRIER AND/OR FORWARDER must also:
                    <ListItemContent>
                      not disclose any Personal Data to overseas recipients without the prior written consent of
                      Owner/Shipper
                    </ListItemContent>
                    <ListItemContent>
                      comply with all applicable legal and regulatory requirements for disclosing the Personal Data to
                      overseas recipients; and
                    </ListItemContent>
                    <ListItemContent>
                      take all reasonable steps to ensure that overseas recipients of the disclosed Personal Data are
                      made aware of any applicable privacy and Data Privacy Act and regulatory requirements.
                      <OrderedListLetter>
                        <ListItemContent>
                          Upon termination or expiration of this Agreement subject to any legal or auditing requirements
                          to retain the Personal Data, CARRIER AND/OR FORWARDER must promptly return to Owner/Shipper
                          or, if requested by Owner Shipper, destroy all copies of the Personal Data, in which case any
                          right to use, copy or disclose that Personal Data ceases.
                        </ListItemContent>
                        <ListItemContent>
                          CARRIER AND/OR FORWARDER will indemnify and keep indemnified Owner/Shipper for any cost, loss,
                          expense, liability, compensation, claim, fine or penalty suffered or incurred by Owner/Shipper
                          as a result of CARRIER AND/OR FORWARDER breaching its obligations except to the extent that
                          such abilities have been caused or contributed to by Owner/Shipper.
                        </ListItemContent>
                      </OrderedListLetter>
                    </ListItemContent>
                    <ListItemContent>
                      <Highlighter>VENUE OF ACTIONS.</Highlighter> Any court action arising from this transaction will
                      be subjected to the jurisdiction of the courts of Pasay City or Makati City.
                    </ListItemContent>
                    <ListItemContent>
                      <Highlighter>APPLICABILITY.</Highlighter> Terms and conditions shall apply to and inure to the
                      benefit of Carrier and/or Forwarder and its authorized agents and affiliated companies and their
                      officers, directors and employees.
                    </ListItemContent>
                    <ListItemContent>
                      <Highlighter>SEVERABILITY.</Highlighter> If any part of these terms is not enforceable, this shall
                      not affect the enforceability ofany other part.
                    </ListItemContent>
                  </UnorderedList>
                </ParagraphDivContent>
              </ContentWrapper>
            </ContentBox>
          </SectionContainer>
        </ContentContainer>
      </Main>
    </ThemeProvider>
  );
}

export default App;
