import { createGlobalStyle } from "styled-components";

import GothamRoundedLight from "./GothamRoundedLight_21020.ttf";
import GothamRoundedRegular from "./GothamRoundedBook_21018.ttf";
import GothamRoundedMedium from "./GothamRoundedMedium_21022.ttf";
import GothamRoundedBold from "./GothamRoundedBold_21016.ttf";

export default createGlobalStyle`
    @font-face {
        font-family: 'Gotham Rounded';
        src: local('Gotham Rounded Light'),
        url(${GothamRoundedLight}) format("truetype");
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Gotham Rounded';
        src: local('Gotham Rounded Regular'),
        url(${GothamRoundedRegular}) format("truetype");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Gotham Rounded';
        src: local('Gotham Rounded Medium'),
        url(${GothamRoundedMedium}) format("truetype");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'Gotham Rounded';
        src: local('Gotham Rounded Bold'),
        url(${GothamRoundedBold}) format("truetype");
        font-weight: 700;
        font-style: normal;
    }
`;
